import React from 'react';

import { NotificationContent } from './NotificationContent';
import { useFavouritesStore } from '@Stores/StoreContext';

const Notification: React.FC = () => {
  const [notification, notificationClose] = useFavouritesStore((state) => [
    state.notification,
    state.notificationClose,
  ]);

  if (!notification) {
    return null;
  }

  return (
    <NotificationContent
      notification={notification}
      onClose={notificationClose}
    />
  );
};

export default Notification;
