import { Heading, StarRating, TripadvisorRating, Image } from '@loveholidays/design-system';
import React from 'react';

import { HotelInfo as HotelInfoType } from '../interfaces';

export const HotelInfoCard: React.FC<HotelInfoType> = ({
  name,
  image,
  starRating,
  reviewRating,
  reviewCount,
}) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: image && image.url ? 'auto 1fr' : '1fr',
      gap: 'xs',
      alignItems: 'center',
    }}
  >
    {image && image.url && (
      <Image
        fluid={false}
        sx={{
          width: '64px',
          height: '64px',
          display: 'inline-block',
          objectFit: 'cover',
          borderRadius: '8',
        }}
        src={image.url}
        alt={image.description}
        width={64}
        height={64}
      />
    )}
    <div>
      <Heading
        variant="smallbold"
        as="h4"
        sx={{
          marginBottom: 0,
        }}
      >
        {name}
      </Heading>
      <div>
        <StarRating
          rating={starRating}
          sx={{
            marginRight: '2xs',
          }}
        />
        <TripadvisorRating
          rating={reviewRating}
          reviewCount={reviewCount}
          sx={{
            padding: '3xs', // legacyPadding
          }}
        />
      </div>
    </div>
  </div>
);
