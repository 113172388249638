import { Label, Button, Link } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { HotelInfoCard } from './HotelInfoCard';
import { Notification as NotificationItemType } from '../interfaces';
import { FavouritesModal, TypeFavouritesModal } from '../modals/FavouritesModal';
import { usePathTemplate } from '@Core/usePathTemplate';

interface NotificationAddedContentProps extends NotificationItemType {
  onClose: () => void;
}

export const NotificationAddedContent: React.FC<NotificationAddedContentProps> = ({
  listInfo,
  masterId,
  hotelInfo,
  onClose,
}) => {
  const { t } = useTranslation();
  const favouritesPath = usePathTemplate('favourites');

  return (
    <Fragment>
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          marginBottom: 'xs',
        }}
      >
        <Label
          variant="medium"
          as="p"
          sx={{
            marginRight: 's',
          }}
        >
          {listInfo && (
            <Fragment>
              <span>{t('favourites.savedTo')}</span>
              <Link
                href={`${favouritesPath}${listInfo.id}`}
                internal
                sx={{
                  display: 'block',
                  fontWeight: 'bold',
                }}
              >
                {listInfo.title}
              </Link>
            </Fragment>
          )}

          {!listInfo && t('favourites.savedToLastList')}
        </Label>
        <FavouritesModal
          type={TypeFavouritesModal.Add}
          masterId={masterId}
        >
          {(setModalOpen) => (
            <Button
              variant="Secondary"
              size="36"
              onClick={() => {
                setModalOpen();
                onClose();
              }}
            >
              {t('favourites.change')}
            </Button>
          )}
        </FavouritesModal>
      </div>
      {hotelInfo && <HotelInfoCard {...hotelInfo} />}
    </Fragment>
  );
};
