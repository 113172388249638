import React from 'react';

import { NotificationAddedContent } from './NotificationAddedContent';
import { NotificationRemovedContent } from './NotificationRemovedContent';
import { Notification, NotificationType } from '../interfaces';
import { slideUp } from '@UX/keyframes';

export interface NotificationContentProps {
  notification: Notification;
  onClose: () => void;
}

export const NotificationContent: React.FC<NotificationContentProps> = ({
  notification,
  onClose,
}) => {
  const { type, previousLists, previousHotelRemoved, isOpened } = notification;

  return (
    <div
      sx={{
        display: isOpened ? 'block' : 'none',
        position: 'fixed',
        width: ['auto', '100%'],
        maxWidth: ['auto', '400px'],
        padding: 's',
        backgroundColor: 'backgroundWhite',
        color: 'textDefault',
        zIndex: 'toast',
        marginLeft: [0, '-200px'],
        boxShadow: 'elevationFixed',
        boxSizing: 'border-box',
        textAlign: 'left',
        animation: [`${slideUp} 0.3s cubic-bezier(.25,.75,.5,1)`],

        ...(type === NotificationType.Removed && {
          backgroundColor: 'backgroundDark',
          color: 'textLight',
          boxShadow: 'elevationHover',
          borderRadius: '8',
          bottom: ['l', 'xl'],
          left: ['l', '50%'],
          right: ['l', 'auto'],
        }),

        ...(type === NotificationType.Added && {
          paddingX: 'xs',
          paddingY: 'l',
          bottom: [0, 'xl'],
          left: [0, '50%'],
          right: [0, 'auto'],
        }),
      }}
    >
      {type === NotificationType.Added && (
        <NotificationAddedContent
          {...notification}
          onClose={onClose}
        />
      )}
      {type === NotificationType.Removed && (!!previousLists || !!previousHotelRemoved) && (
        <NotificationRemovedContent {...notification} />
      )}
    </div>
  );
};
