import { Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { Notification as NotificationItemType } from '../interfaces';
import { getPageTypeClientSync } from '@Contexts/contexts';

export const NotificationRemovedContent: React.FC<NotificationItemType> = ({
  listInfo,
  previousHotelRemoved,
}) => {
  const { t } = useTranslation();
  const pageType = getPageTypeClientSync(window?.location?.pathname);

  const selectedListInfo = pageType === 'favouriteslistdetails' ? previousHotelRemoved : listInfo;

  return (
    <div
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Label
        variant="large"
        as="p"
        sx={{
          marginRight: 's',
        }}
      >
        {selectedListInfo && (
          <Fragment>
            <span>{t('favourites.removedFrom')}</span>
            <span
              sx={{
                display: 'block',
              }}
            >
              {selectedListInfo.title}
            </span>
          </Fragment>
        )}

        {!selectedListInfo && t('favourites.removedFromAllLists')}
      </Label>
    </div>
  );
};
